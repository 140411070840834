class ValidarCpfCnpj {
  cpf(cpf, obrigadoriedadeCnpjCpf) {
    if (typeof cpf !== "string") return false;
    if (!obrigadoriedadeCnpjCpf && cpf === "") return true;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
      return false;
    }
    cpf = cpf.split("");
    const validator = cpf
      .filter((digit, index, array) => index >= array.length - 2 && digit)
      .map((el) => +el);
    const toValidate = (pop) =>
      cpf
        .filter((digit, index, array) => index < array.length - pop && digit)
        .map((el) => +el);
    const rest = (count, pop) =>
      ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
        10) %
        11) %
      10;
    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
  }

  cnpj(cnpj, obrigadoriedadeCnpjCpf) {
    if (typeof cnpj !== "string") return false;
    if (!obrigadoriedadeCnpjCpf && cnpj === "") return true;
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.padStart(14, "0");
    if (isExceptionCnpj(cnpj)) return false;
    if (!isValidCnpjNumber(cnpj)) return false;
    const primeriaSoma = sumCnpjDigit(cnpj, 12);
    if (calculateRestCnpj(primeriaSoma) !== parseInt(cnpj.substring(12, 13))) {
      return false;
    }
    const segundaSoma = sumCnpjDigit(cnpj.substring(0, 13), 13);
    if (calculateRestCnpj(segundaSoma) !== parseInt(cnpj.substring(13, 14))) {
      return false;
    }
    return true;
  }
}

const isExceptionCnpj = (value) => {
  const lstCpfException = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999"
  ];
  return lstCpfException.includes(value);
};

const isValidCnpjNumber = (value) => {
  if (
    value === "" ||
    value === undefined ||
    value === null ||
    value.legth < 14
  ) {
    return false;
  }
  return true;
};

const sumCnpjDigit = (value, length) => {
  let pos = length - 7;
  let sumDigit = 0;
  for (let i = length; i >= 1; i--) {
    sumDigit += value.charAt(length - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  return sumDigit;
};

const calculateRestCnpj = (value) => {
  return value % 11 < 2 ? 0 : 11 - (value % 11);
};

export default ValidarCpfCnpj;
