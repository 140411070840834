import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
  Loading,
} from "../../../../components";
import {
  PedidosService,
  OperacaoFiscalService,
  PessoaService,
  MeioPagamentoService,
  OrigemMercadoriaService,
  UnidadeComercialService,
} from "../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../utils";
import { NfSaidaItem } from "../../../../entities";
import PedidoStepperPdvContent from "./pedido-stepper-pdv-content.component";
import { GRID_CHECKBOX_SELECTION_COL_DEF as checkboxGrid } from "@mui/x-data-grid";
import shortid from "shortid";
import { totalVenda, formatProdutosTotalizadoresDto } from "../../../../utils";

const ImportarPedidoPdvDialog = ({
  openImportarPedidoDialog,
  setOpenImportarPedidoDialog,
  setListaProdutosVenda,
  setListaPagamentosVenda,
  operacaoFiscal,
  meioPagamentoList,
  setMeioPagamentoList,
  operacaoFiscalList,
  setOperacaoFiscalList,
  setClienteSelecionado,
  pdvEntity,
  setVenda,
}) => {
  //service fora do componente
  const pedidosService = new PedidosService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const pessoaService = new PessoaService();
  const meioPagamentoService = new MeioPagamentoService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [filtrosDialog, setFiltrosDialog] = useState({});
  const [pedidos, setPedidos] = useState([]);
  const [pedidosSelecionados, setPedidosSelecionados] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [pedidoSelectionModel, setPedidoSelectionModel] = useState([]);
  const [itemSelectionModel, setItemSelectionModel] = useState([]);
  const [itemEditado, setItemEditado] = useState(false);
  const [totalListaPedidos, setTotalListaPedidos] = useState(0);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const steps = ["Selecionar Pedidos", "Selecionar Itens e Importar"];
  const colunas = [
    {
      ...checkboxGrid,
      headerClassName: "table-pdv-checkbox",
    },
    {
      field: "serieDocumento",
      headerName: "Série",
      headerClassName: "table-pdv-header",
      flex: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Doc",
      headerClassName: "table-pdv-header",
      flex: 105,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      headerClassName: "table-pdv-header",
      flex: 170,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      headerClassName: "table-pdv-header",
      flex: 250,
      valueGetter: (params) =>
        operacaoFiscalList.find((item) => item.id === params.value)?.descricao,
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      headerClassName: "table-pdv-header",
      flex: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id === params.value)?.nomeRazaoSocial,
    },
    {
      field: "pessoaVendedorId",
      headerName: "Vendedor",
      headerClassName: "table-pdv-header",
      flex: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id === params.value)?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total do Pedido",
      headerClassName: "table-pdv-header",
      flex: 250,
      valueGetter: (params) => {
        const totalPedido =
          params.value
            .map((item) => item.valorUnitario * item.saldoExportar)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalPedido);
      },
    },
  ];

  useEffect(() => {
    if (openImportarPedidoDialog) {
      buscarPedidos();
      buscarUnidadesComerciais();
      buscarOrigensMercadoria();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openImportarPedidoDialog]);

  const buscarPedidos = async (filtro) => {
    setLoading(true);
    const filtros = {
      ...filtro,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
    };
    const result = await pedidosService.getAllFiltroAvancado(filtros);
    if (!result.isAxiosError) {
      setTotalListaPedidos(result.data.count);
      setPedidos(result.data.rows);
      buscarRelacionamentosPedido(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarPedidosAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
      restritiva: true,
    };
    if (filtrosAvancadosTemp.dataInicial && filtrosAvancadosTemp.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataInicial,
        ate: filtrosAvancadosTemp.dataFinal,
      });
    }
    delete filtrosAvancadosTemp.dataInicial;
    delete filtrosAvancadosTemp.dataFinal;
    const result = await pedidosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setPedidos(result.data.rows);
      setTotalListaPedidos(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosPedido = (listaPedido) => {
    const idsPessoas = [];
    const idsOperacoesFiscais = [];
    const idsMeiosPagamento = [];
    const idsCondicoesVenda = [];
    for (const objeto of listaPedido) {
      idsOperacoesFiscais.push(objeto.operacaoFiscalId);
      if (objeto.pessoaClienteId) idsPessoas.push(objeto.pessoaClienteId);
      if (objeto.pessoaVendedorId) idsPessoas.push(objeto.pessoaVendedorId);
      if (objeto.meioPagamentoId)
        idsMeiosPagamento.push(objeto.meioPagamentoId);
      if (objeto.condicaoPagamentoId)
        idsCondicoesVenda.push(objeto.condicaoPagamentoId);
    }
    buscarOperacoesFiscais([...new Set(idsOperacoesFiscais)]);
    buscarPessoas([...new Set(idsPessoas)]);
    buscarMeiosPagamento([...new Set(idsMeiosPagamento)]);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
      restritiva: true,
      porIntervalo: [],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date !== "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleImportarPedido = async () => {
    await createNfSaida(
      pedidosSelecionados[0],
      pedidosSelecionados,
      itensSelecionados
    );
    setLoading(false);
    setOpenImportarPedidoDialog(false);
    resetarDadosImportacao();
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  //usar a entidade para criar olhar a importacao de nf-saida
  const createNfSaida = async (pedido, pedidos, itens) => {
    setLoading(true);
    const nfSaidaItens = itens
      .filter((item) => itemSelectionModel.indexOf(item.id) > -1)
      .map((item, index) => {
        const nfSaidaItem = new NfSaidaItem(
          item,
          index,
          operacaoFiscal,
          origensMercadoriaList,
          unidadeList
        );
        return {
          ...nfSaidaItem,
          id: shortid.generate(),
          produto: item.produto,
          valor: parseFloat(item.valorUnitario.toFixed(2)),
          subtotal:
            parseFloat(item.valorUnitario.toFixed(2)) *
            parseFloat(item.saldoExportar),
        };
      });
    const nfSaidaPagamentos =
      pedido?.status === "ABERTO" &&
      itens?.length === itemSelectionModel?.length &&
      !itemEditado &&
      pedidosSelecionados.length === 1
        ? pedido?.pagamentos?.map((pgto, index) => ({
            id: shortid.generate(),
            condicaoPagamentoId: pgto.condicaoPagamentoId,
            meioPagamentoSelecionado: meioPagamentoList.find(
              (meioPagamento) => meioPagamento.id === pgto.meioPagamentoId
            ),
            meioPagamentoId: pgto.meioPagamentoId,
            dataVencimento: pgto.vencimento,
            valorTitulo: pgto.valorTitulo,
            numeroTitulo: index,
          }))
        : [];
    const informacoesAdicionais = pedidos
      .map((pedido) => {
        return `Importado do pedido ${pedido.serieDocumento ?? ""}/${
          pedido.numeroDocumento ?? ""
        }`;
      })
      .join("\n");
    let cliente = {};
    if (pedido.pessoaClienteId) {
      cliente = listaPessoas.find((item) => item.id === pedido.pessoaClienteId);
    }
    const nfSaida = {
      pedidoImport: true,
      informacoesAdicionais,
      pedidoIds: pedidos.map((pedido) => pedido.id),
      vendedorId: pedido?.pessoaVendedorId,
      desconto: pedido.desconto ?? 0,
      acrescimo: pedido.acrescimo ?? 0,
      despesas: pedido.despesas ?? 0,
      prestadorServicoId: pedido.pessoaProficionalId,
    };
    const formatItens = formatProdutosTotalizadoresDto(
      nfSaidaItens,
      totalVenda(nfSaida, nfSaidaItens),
      nfSaida
    );
    pdvEntity.setOperacaoFiscal(operacaoFiscal);
    pdvEntity.setChangeItens(null, formatItens);
    pdvEntity.setChangePagamentos(nfSaidaPagamentos);
    pdvEntity.setIdentificarCliente(cliente);
    pdvEntity.setDadosImportacao(nfSaida);
    setListaProdutosVenda(formatItens);
    setListaPagamentosVenda(nfSaidaPagamentos);
    setClienteSelecionado(cliente);
    setVenda(pdvEntity);
  };

  const resetarDadosImportacao = () => {
    setFiltrosDialog({});
    setItemSelectionModel([]);
    setItensSelecionados([]);
    setPedidoSelectionModel([]);
    setPedidosSelecionados([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarPedidoDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro === "simples") {
      delete props.filtros.status;
      buscarPedidos(props.filtros);
    } else {
      buscarPedidosAvancado(props.filtros);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={openImportarPedidoDialog}
      onClose={handleCancelarImportacao}
    >
      <CardHeader title="Importar Pedido" className="m-2" />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <PedidoStepperPdvContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleImportarPedido={handleImportarPedido}
        handleCancelarImportacao={handleCancelarImportacao}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        colunas={colunas}
        pedidos={pedidos}
        totalListaPedidos={totalListaPedidos}
        pedidosSelecionados={pedidosSelecionados}
        setPedidosSelecionados={setPedidosSelecionados}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        buscarPedidosAvancado={buscarPedidosAvancado}
        listaClientes={listaPessoas}
        itemSelectionModel={itemSelectionModel}
        setItemSelectionModel={setItemSelectionModel}
        pedidoSelectionModel={pedidoSelectionModel}
        setPedidoSelectionModel={setPedidoSelectionModel}
        setItemEditado={setItemEditado}
      />
      <Loading loading={loading} />
    </Dialog>
  );
};

export default ImportarPedidoPdvDialog;
